import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import './_Footer.scss'

class Footer extends Component {
    render() {
        return (
            <div className="footer_wrap">
                <div className="col_3">
                    <div>
                        <h5 style={{margin: 0, textAlign: 'center'}}>Los Angeles, CA</h5>
                    </div>
                    <a href="https://www.linkedin.com/in/zo%C3%ABmelisko/" target="_blank">
                        <div style={{textAlign: 'center'}}>
                            <FontAwesomeIcon icon={faLinkedinIn} size="2x"/>
                        </div>
                    </a>
                    <div>
                        <h5 style={{margin: 0, textAlign: 'center'}}>zoe@zoemelisko.com</h5>
                    </div>
                </div>
                <div>
                    <p>© Copyright Zoë Melisko 2022</p>
                </div>
                <br/>
            </div>
        )
    }
}

export default Footer;