import React, {Component} from "react";

import './_About.scss'

class About extends Component {
    render() {
        return (
            <div>
                <h1>This is the About Page!</h1>
            </div>
        );
    }
}

export default About;