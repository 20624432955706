import React, {Component} from "react";
import Menu from '../../components/Menu/Menu'
import Footer from "../../components/Footer/Footer";
import LeftChunk from "../../components/LeftChunk/LeftChunk";
import fieri from "../../assets/images/Fieri.png";
import trkx from "../../assets/images/TRKX.png";
import travel from "../../assets/images/Concierge.png";
import choisi from "../../assets/images/Choisi.png";
import upcomer from "../../assets/images/Upcomer.png";
import brgr from "../../assets/images/Burger.png";
import logo from "../../assets/images/logo-zm.png";


class Landing extends Component {
    state={
        images: [
            {
                id: 1,
                img: fieri,
                title: 'Fieri Finder',
                role: 'Design + Development',
                des: 'A single app dedicated to helping users discover Guy Fieri food spots.',
                link: 'fieri-finder-case-study'
            },
            {
                id: 2,
                img: trkx,
                title: 'TRKX',
                role: 'UI Design + Development',
                des: 'Building a new user interface for a transportation logistics company.',
                link: 'trkx-case-study',
            },
            {
                id: 3,
                img: travel,
                title: 'Concierge',
                role: 'UX + UI Design',
                des: 'All in one travel app focused on connecting users to local experts for unique travel experiences.',
                link: 'concierge-case-study',
            },
            {
                id: 4,
                img: choisi,
                title: 'Choisi',
                role: 'UX + UI Design',
                des: 'Tailoring e-commerce functionality for the online luxury fashion experience.',
                link: 'choisi-case-study',
            },
            {
                id: 5,
                img: upcomer,
                title: 'Upcomer',
                role: 'UX + UI Design',
                des: 'Increasing user engagement of community members for a eSports app.',
                link: 'upcomer-case-study',
            },
            {
                id: 6,
                img: brgr,
                title: 'Burger Records',
                role: 'UX + UI Design',
                des: 'Creating a cohesive e-commerce experience for an independent record label.',
                link: 'burger-records-case-study',
            },
        ]
    };
    render() {
        let myComponents=this.state.images.map(
            el=>{
                return <LeftChunk style={{zIndex: 40}} key={el.id} img={el.img} title={el.title} role={el.role} des={el.des} link={el.link} />
            })
        return (
            <div id="landing_wrap">
                <div id="mobile_nav">
                    <div id="logo_wrap">
                        <a  href={'/'}><img src={logo} alt="logo" id="my_logo"/></a>
                    </div>
                    <Menu className="upper_layer" />
                </div>
                <div className="intro_wrap section">

                    <div className="landing_text_wrap">
                        <h1 className="landing_intro wavy upper_layer" style={{position: "relative"}}>Hey, I'm Zoë!</h1>
                        <svg className="wavy_line_wrap" xmlns="http://www.w3.org/2000/svg" version="1.1">
                            <path className="wavy_line" d="m 46 46 Q 10 10 40 40 T 100 40 T 150 40 T 200 40 T 250 40 T 300 40 T 350 40 T 400 50" strokeLinecap="round" fill="none" strokeLinecap="round"/>
                        </svg>
                        <svg className="wavy_line_wrap_mobile" xmlns="http://www.w3.org/2000/svg" version="1.1">
                            <path className="wavy_line_mobile" d="m 46 46 Q 10 10 40 40 T 100 40 T 150 40 T 200 40  " strokeLinecap="round" fill="none" strokeLinecap="round"/>
                        </svg>
                        <h1 className="landing_tagline">I <span className="purple">u</span>nder<span className="green">s</span>tand<span className="blue">,</span> desi<span className="yellow">g</span>n, & <span className="salmon">d</span>evel<span className="blue">o</span>p u<span className="yellow">s</span>er <span className="coral">e</span>xper<span className="green">i</span>enc<span className="purple">e</span>s!</h1>
                    </div>
                </div>
                {/*{myComponents}*/}
                <Footer />
            </div>
        );
    }
}

export default Landing;