import React, {Component} from "react";
import NavBar from "../../components/NavBar/NavBar";

// import './Main.css'


class Main extends Component {
    render() {
        return (
            <div>
                <NavBar />
                <p>This is the homepage!</p>
            </div>
        );
    }
}

export default Main;