import React, {Component} from "react";

import './_LeftChunk.scss';
// import Button from "../Button/Button";
import '../Button/_Button.scss'
import {Link} from "react-router-dom";
import Header from "../CSHeader/Header";

class LeftChunk extends Component {

    render() {
        return(
            <div id="left_chunk_wrap">
                <div id="summary_container">
                    <div id="text_chunk">
                        <div id="img_container_mobile">
                            <img id="chunk_img_mobile" src={this.props.img} alt="Logo"/>
                        </div>
                        <h1 id="text_chunk_title"><b>{this.props.title}</b></h1>
                        <h3 id="text_chunk_role">{this.props.role}</h3>
                        <p id="text_chunk_desc">{this.props.des}</p>
                        <Link className="cta" to={{pathname: `/${this.props.link}`}}>
                            <span>View Case Study</span>
                        </Link>
                    </div>
                    <div id="img_container">
                        <img id="chunk_img" src={this.props.img} alt="Logo"/>
                    </div>
                </div>
            </div>
        )
    }
}

export default LeftChunk;