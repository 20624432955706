import React, {Component} from "react";
import "./_Header.scss"

class Header extends Component {
    render() {
        return (
            <div id="case_study_header" className="header_wrap padding upper_layer">
                <div>
                    <div>
                        <h1>{this.props.brief}</h1>
                    </div>
                        <div className="circle">

                        </div>
                </div>
                <div className="header_detail_wrap col_4">
                    <div className="header_detail">
                        <h5 style={{color: "grey"}}>{this.props.title}</h5>
                        <p>{this.props.client}</p>
                    </div>
                    <div className="header_detail">
                        <h5 style={{color: "grey"}}>Role</h5>
                        <p>{this.props.role}</p>
                    </div>
                    <div className="header_detail">
                        <h5 style={{color: "grey"}}>Tools</h5>
                        <p>{this.props.tools}</p>
                    </div>
                    <div className="">
                        <h5><a href="/">{this.props.view}</a></h5>
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;