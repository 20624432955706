import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import "./_App.scss";
import Landing from "../Landing/Landing";
import About from "../About/About";
import Main from "../Main/Main";
// import BrgrCS from "../../pages/BrgrCS/BrgrCS";
// import ChoisiCS from "../../pages/ChoisiCS/ChoisiCS";
// import UpCS from "../../pages/UpCS/UpCS";
// import FieriCS from "../../pages/FieriCS/FieriCS";
// import TrkxCS from '../../pages/TrkxCS/TrkxCS'
// import ConciergeCS from "../ConciergeCS/ConciergeCS";

function App() {
    return (
        <Router id="root">
            <Switch>
                <Route exact path="/" element={ <Main /> } />
                <Route exact path="/index.html" element={ <Main /> } />
                {/*<Route path="/Main" element={ <Main/> } />*/}
                {/*<Route path="/About" element={ <About/> } />*/}
                {/*<Route path="/burger-records-case-study" element={ <BrgrCS/> } />*/}
                {/*<Route path="/concierge-case-study" element={ <ConciergeCS/> } />*/}
                {/*<Route path="/choisi-case-study" element={ <ChoisiCS/> } />*/}
                {/*<Route path="/upcomer-case-study" element={ <UpCS/> } />*/}
                {/*<Route path="/fieri-finder-case-study" element={ <FieriCS/> } />*/}
                {/*<Route path="/trkx-case-study" element={ <TrkxCS/> } />*/}
            </Switch>
        </Router>
    );
}

export default App;
