import React from 'react';
import { createRoot } from 'react-dom/client';
import "uikit/dist/js/uikit.min.js";
import "uikit/dist/css/uikit.min.css";

import {
    BrowserRouter as Router,
} from "react-router-dom";


import App from "./pages/App/App";
import Main from "./pages/Main/Main";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Main />
);

// ReactDOM.createRoot(
//     <Router>
//         <App/>
//     </Router>,
//     document.getElementById('root')
// );

